var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.display)?_c('div',{staticClass:"container-fluid d-flex flex-column",style:({
    minHeight: '100vh',
    padding: '30px',
    backgroundImage: 'url(https://media.tok.tv/alive/assets/static/img/eni.jpg)',
    // backgroundColor: 'rgba(0,0,0,0.5)',
    // backgroundBlendMode: 'multiply',
    backgroundSize: 'cover',
  })},[_c('transition',{attrs:{"enterActiveClass":"animate__animated animate__fadeIn","leaveActiveClass":"animate__animated animate__fadeOut","appear":"","mode":"out-in"}},[((_vm.redeem && _vm.displayRedeem))?_c('Redeem'):_c('div',{staticClass:"row",style:({flex: 'auto'})},[_c('div',{staticClass:"col-lg-12 col-md-12 text-center flex-column d-flex justify-content-center align-items-center"},[_c('div',{staticClass:"Box"},[_c('div',{staticClass:"Box__header"},[_c('div',{staticClass:"row pt-3 pb-3 pl-4 pr-2"},[_c('div',{staticClass:"col-6 d-flex align-items-center justify-content-start justify-content-md-end pr-md-5 pr-sm-0"},[_c('EniLogo')],1),_c('div',{staticClass:"col-6 d-flex align-items-center justify-content-md-start justify-content-end pl-md-5 pl-sm-0"},[_c('img',{attrs:{"height":"40","src":"https://media.tok.tv/alive/assets/static/img/newlogoh.png","alt":""}})])])]),_c('div',{staticClass:"Box__body"},[_c('div',{staticClass:"row  mr-0 ml-0"},[(!_vm.isActivated)?_c('div',{staticClass:"col-12 fz20 pt-3"},[_vm._v(" Eni gas e luce ti aspetta il "),_c('span',{staticClass:"bld"},[_vm._v("3 Settembre")]),_vm._v(" alle 21:00 per partecipare al live streaming del concerto "),_c('span',{staticClass:"bld"},[_vm._v("sostenibile")]),_vm._v(" di "),_c('span',{staticClass:"bld"},[_vm._v("Gianna Nannini")]),_vm._v(" dal Teatro Antico di Taormina."),_c('br'),_vm._v(" Per vedere il trailer, ti verrà richiesto il codice che trovi nella tua area My Eni. ")]):_c('div',{staticClass:"col-12 fz20 pt-3"},[_c('EniActivated')],1)]),_c('div',{staticClass:"row mr-0 ml-0 w-100"},[[_c('div',{staticClass:"col-12 mt-3 d-flex justify-content-center"},[_c('EniVideos',{attrs:{"category":_vm.category,"register":_vm.register}})],1)]],2)])])])])],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }