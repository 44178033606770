<template>
  <div
    v-if="display"
    class="container-fluid d-flex flex-column"
    :style="{
      minHeight: '100vh',
      padding: '30px',
      backgroundImage: 'url(https://media.tok.tv/alive/assets/static/img/eni.jpg)',
      // backgroundColor: 'rgba(0,0,0,0.5)',
      // backgroundBlendMode: 'multiply',
      backgroundSize: 'cover',
    }"
  >
    <transition enterActiveClass="animate__animated animate__fadeIn" leaveActiveClass="animate__animated animate__fadeOut" appear mode="out-in">
    <Redeem v-if="(redeem && displayRedeem)" />
    <div class="row" :style="{flex: 'auto'}" v-else>
      <div class="col-lg-12 col-md-12 text-center flex-column d-flex justify-content-center align-items-center">
        <div class="Box">
          <div class="Box__header">
            <div class="row pt-3 pb-3 pl-4 pr-2">
              <div class="col-6 d-flex align-items-center justify-content-start justify-content-md-end pr-md-5 pr-sm-0">
                <EniLogo />
              </div>
              <div class="col-6 d-flex align-items-center justify-content-md-start justify-content-end pl-md-5 pl-sm-0">
                <img height="40" src="https://media.tok.tv/alive/assets/static/img/newlogoh.png" alt="">
              </div>
            </div>
          </div>
          <div class="Box__body">
            <div class="row  mr-0 ml-0">
                <div class="col-12 fz20 pt-3" v-if="!isActivated">
                  Eni gas e luce ti aspetta il <span class="bld">3 Settembre</span> alle 21:00 per partecipare al live streaming del concerto <span class="bld">sostenibile</span> di <span class="bld">Gianna Nannini</span> dal Teatro Antico di Taormina.<br />
                  Per vedere il trailer, ti verrà richiesto il codice che trovi nella tua area My Eni.
              </div>
              <div class="col-12 fz20 pt-3" v-else>
                  <EniActivated />
                  <!--
                  <span v-if="!eniStarting && !eniStarted">Il tuo codice è stato inserito correttamente. Eni gas e luce ti ricorda che, per guardare in streaming il concerto di Gianna Nannini, dovrai tornare su questa pagina il 3 Settembre alle 21:00</span>
                  <span v-if="eniStarting && !eniStarted">{{ $t('eniStarting') }}</span>
                  <span v-if="eniStarted && !eniCompleted">{{ $t('eniStarted') }}</span>
                  <span v-if="eniCompleted">{{ $t('eniCompleted') }}</span>
                  -->
              </div>
              <!--<div class="col-12 fz20 pt-3" v-if="!started">La registrazione all’evento con il codice disponibile su My Eni sarà possibile a partire dal 20 luglio 2021</div>-->
            </div>
            <div class="row mr-0 ml-0 w-100">
              <template>
                <div class="col-12 mt-3 d-flex justify-content-center">
                  <EniVideos :category="category" :register="register" />
                </div>
              </template>
            </div>
            <!--
            <div class="row">
              <div class="col-12 fz14 text-justify">
                *La neutralità carbonica dell’evento è stata certificata da “Zero Carbon Target” mediante il rilascio della certificazione “Evento Zerocarbontarget”. Tale certificazione si basa sulla quantificazione delle emissioni di CO2 relative alla organizzazione e realizzazione dell’evento, riducendone ove possibile l’entità, e nella verifica che le stesse siano interamente annullate. La quantificazione effettuata da Zero Carbon Target tiene conto delle emissioni di CO2 correlate all’espletamento delle attività necessarie per l’organizzazione dell’evento specifico (ossia trasporti e logistica; consumo di energia elettrica e termica; emissioni dirette di CO2; utilizzo di risorse; location scelta per l’organizzazione dell’evento). L’annullamento delle emissioni di CO2 relative alla organizzazione e realizzazione dell’evento avverrà entro 10 giorni dalla data dell’evento.
              </div>
            </div>
            -->
          </div>
        </div>
      </div>
    </div>
    </transition>
    <!--
    <div class="row">
      <div class="col-6 text-left">
        <img src="https://media.tok.tv/alive/assets/static/img/bianco.svg" />
      </div>
      <div class="col-6 text-right text-white">
        <img src="https://media.tok.tv/alive/assets/static/img/aliveWhite.png" height="60px" />
      </div>
    </div>
    -->
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex';
import { get, intersection } from 'lodash';
import EniLogo from './glyphs/EniLogo.vue';
import EniVideos from './EniVideos.vue';
import Redeem from './ENIRedeem.vue';
import EniActivated from './EniActivated.vue';

// import Countdown from 'countdown-vue';
// import FlipCountdown from 'vue2-flip-countdown';

export default {
  components: {
    EniActivated,
    Redeem,
    EniVideos,
    EniLogo,
  },
  name: 'ElisaEni',
  data() {
    return {
      activated: false,
      display: false,
      debugStart: '2021-07-17 23:14:00',
      start: '2021-07-29 19:00:00',
      end: '2021-07-29 19:00:00',
      started: false,
      ended: false,
      get,
      category: false,
      eniStarting: false,
      eniStarted: false,
      eniCompleted: false,
    };
  },
  methods: {
    ...mapActions(['getMe']),
    ...mapMutations(['appReady', 'redirectToIP', 'setReferer', 'setLoginInfo']),
    register() {
      this.$ls.set('referrer', '/dieci-milioni-c', 10 * 60 * 1000);
      this.redirectToIP({
        router: this.$router,
        registered: true,
        event: 'eni-c',
      });
    },
    // eslint-disable-next-line consistent-return
    getToken() {
      this.$ls.set('referrer', '/dieci-milioni-c', 10 * 60 * 1000);
      const status = this.$ls.get('storageAccessData');
      if (status && status.token) {
        this.setLoginInfo(status);
        this.getMe();
        return status.token;
      }
      this.register();
    },
  },
  mounted() {
    this.eniStarting = this.conf[this.env].eniStarting;
    this.eniStarted = this.conf[this.env].eniStarted;
    this.eniCompleted = this.conf[this.env].eniCompleted;
    const token = this.getToken();
    const ep = '/category/c68a3f24-e574-11eb-915b-06daa3423294';
    this.api.get(ep, {
      headers: {
        'x-tok-token': token,
        'Accept-Language': this.locale,
      },
    })
      .then((response) => {
        this.category = response.data;
        this.display = true;
        const validArray = intersection(this.me.active_offers, this.category.videos[0].offers);
        this.activated = validArray.length > 0;
        this.appReady(true);
      })
      .catch((e) => {
        console.log(e);
      });
  },
  computed: {
    ...mapState([
      'displayRedeem',
      'baseApiUrl',
      'api',
      'availableImageSize',
      'loginInfo',
      'me',
      'locale',
      'redeem',
      'conf',
      'env',
    ]),
    isActivated() {
      const validArray = intersection(this.me.active_offers, this.category.videos[0].offers);
      return validArray.length > 0;
    },
  },
};
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;700&display=swap');
  .fzBigger {
    font-size: 60px;
    line-height: 90px;
    font-family: 'Source Sans Pro', sans-serif;
  }
  .fz20 {
    font-size: 20px;
    line-height: 23px;
    font-family: 'Source Sans Pro', sans-serif;
  }
  .fz25 {
    font-size: 25px;
    line-height: 28px;
    font-family: 'Source Sans Pro', sans-serif;
  }
  .fz14 {
    font-size: 14px;
    line-height: 16px;
    font-family: 'Source Sans Pro', sans-serif;
  }
  .fzMd {
    font-size: 30px;
    line-height: 50px;
    font-family: 'Source Sans Pro', sans-serif;
  }
 .fzBig {
   font-size: 40px;
   line-height: 70px;
   font-family: 'Source Sans Pro', sans-serif;
 }
 .text-yellow {
   color: rgb(255, 211, 56);
   text-shadow: 0 0 5px rgba(0, 0, 0, 1);
 }
.bld {
  font-weight: 700;
}

  .CD {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    &__el {
      flex: 0 0 80px;
      display: flex;
      flex-direction: column;
      &__val {
        font-size: 40px;
        padding: 5px;
        font-weight: bold;
        font-family: 'Source Sans Pro', sans-serif;
      }
      &__name {
        font-size: 20px;
        padding: 5px;
        font-family: 'Source Sans Pro', sans-serif;
      }
    }
  }
  .Box {
    // border: 1px solid white;
    width: 100%;
    max-width: 800px;
    margin-bottom: 30px;
    &__header{
      // border-bottom: 1px solid white;
      padding: 0;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      background-color: black;
    }
    &__body{
      padding: 30px;
      background-color: rgba(255, 255, 255, 1);
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
    }
  }
</style>
